import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';
import { TweenMax } from 'gsap';
import set from "@babel/runtime-corejs2/helpers/esm/set";

export default el => {
    const dom = $(el);
    const slides = dom.find('[data-slide]');
    const numbers = dom.find('[data-number]');
    const duration = 1.5;
    const slideLength = 7000;
    const total = slides.length;
    let timeout;
    let listening = false;
    let currentSlide = slides.eq(0);
    let nextSlide = slides.eq(1);
    let currentIndex = 0;
    let nextIndex = 1;
    let nextNextIndex = total > 2 ? 2 : 0;
    let nextNextSlide = slides.eq(nextNextIndex);
    let prevSlide = [];
    let counter = 0;

    const toggleNumbers = index => {
        setTimeout(() => {
            numbers.addClass('opacity-50');
            numbers.eq(index).removeClass('opacity-50');
        }, 250);
    };

    const update = () => {
        // console.log('update', {currentIndex, nextIndex, nextNextIndex,nextSlide,nextNextSlide});
        toggleNumbers(nextIndex);
        prevSlide = currentSlide;
        currentSlide = nextSlide;
        currentIndex = nextIndex;
        nextIndex = currentIndex < (total - 1) ? currentIndex + 1 : 0;
        nextNextIndex = nextIndex < (total - 1) ? nextIndex + 1 : 0;
        nextSlide = slides.eq(nextIndex);
        nextNextSlide = slides.eq(nextNextIndex);
        // console.log('updated', {currentIndex, nextIndex, nextNextIndex,nextSlide,nextNextSlide});
    };

    const animate = () => {
        // console.log('animate');
        const tl = new TimelineMax();
        const temp = nextNextSlide.clone();

        // console.log(temp);

        dom.addClass('will-animate');

        tl.call(() => { dom.append(temp); }, 0)
            .set(temp.get(0), { clearProps: 'all' }, 0)
            .set(temp.get(0), { xPercent: 100, display: 'block', zIndex: 7 }, 0)
            .set(temp.find('[data-image]').get(0), { xPercent: -90 }, 0)
            .set(temp.find('[data-text]').get(0), { opacity: 0.01 }, 0);

        tl.set(nextNextSlide.get(0), { xPercent: 100, zIndex: 8, display: 'block' }, duration)
            .set(nextNextSlide.find('[data-image]').get(0), { xPercent: -90 }, duration)
            .set(nextNextSlide.find('[data-text]').get(0), { opacity: 0.01 }, duration)
            .call(() => { temp.remove(); }, null, null, duration);


        if (prevSlide.length && total > 3) {
            tl.set(prevSlide.get(0), { zIndex: 5 }, duration);
        }

        const logoColor = nextSlide.data('slide').logo;

        tl.set(nextSlide.get(0), { xPercent: 100, display: 'block', zIndex: 9 }, 0)
            .set(nextSlide.find('[data-image]').get(0), { xPercent: -90 }, 0)
            .set(nextSlide.find('[data-text]').get(0), { xPercent: 2, opacity: 0 }, 0)
            .set(currentSlide.get(0), { zIndex: 8 }, 0)
            .to(currentSlide.get(0), duration, { xPercent: -20, ease: 'Quint.easeInOut' }, 0)
            .to(currentSlide.find('[data-text]').get(0), duration, { xPercent: -75, opacity: 0.01, ease: 'Quint.easeInOut' }, 0)
            .to(currentSlide.find('[data-image]').get(0), duration, { xPercent: 0.8 * 20, ease: 'Quint.easeInOut' }, 0)
            .to(nextSlide.find('[data-image]').get(0), duration, { xPercent: 0.001, ease: 'Quint.easeInOut' }, 0)
            .to(nextSlide.find('[data-text]').get(0), duration * 2, { xPercent: 0.001, opacity: 1, ease: 'Quint.easeOut' }, duration / 1.5)
            .to(nextSlide.get(0), duration, { xPercent: 0.001, ease: 'Quint.easeInOut' }, 0)
            .set(currentSlide.get(0), { zIndex: 7 }, duration)
            .call(() => {
                if (logoColor === 'light') {
                    dom.attr('data-dark', '');
                } else {
                    dom.attr('data-dark', null);
                }
                Dispatch.emit(Events.HERO_SLIDE, currentSlide.data('slide'));
            }, null, null, duration * 0.66)
            .call(() => {
                dom.removeClass('will-animate');
            }, null, null, duration);

        update();
        timeout = setTimeout(checkPosition, slideLength);
    };

    const hasFocus = () => {
        if (typeof document.hasFocus === 'function') {
            return document.hasFocus();
        }
        return true;
    };

    const checkPosition = () => {
        if (document.visibilityState !== 'visible') {
            console.log('not-visible');
        }
        if (Viewport.visible(el) && document.visibilityState === 'visible') {
            if (listening || !hasFocus()) {
                listening = false;
                Viewport.off('scroll', checkPosition);
                clearTimeout(timeout);
                timeout = setTimeout(checkPosition, slideLength);
            } else {
                // fade();
                animate();
            }
        } else if (!listening) {

            listening = true;
            document.addEventListener('visibilitychange', checkPosition);
            Viewport.on('scroll', checkPosition);
        }
    };

    const initSlides = () => {
        slides.eq(0).removeClass('z-20').addClass('z-10');
        TweenMax.set(slides.slice(1,slides.length).nodes, { display: 'none' });
        TweenMax.set(slides.get(1), { xPercent: 100, display: 'none' });
        TweenMax.set(slides.eq(1).find('[data-image]').get(0), { xPercent: -90 });
        TweenMax.set(slides.eq(1).find('[data-text]').get(0), { opacity: 0 });

        if (slides.length > 2) {
            TweenMax.set(slides.get(2), { xPercent: -20, display: 'none' });
            TweenMax.set(slides.eq(2).find('[data-image]').get(0), { xPercent: 0.8 * 20 });
            TweenMax.set(slides.eq(2).find('[data-text]').get(0), { opacity: 0 });
        }
    };

    const init = () => {
        if (total === 0) {
            dom.addClass('d-n');
            return;
        }

        dom.find('.lazyload').addClass('lazypreload');

        if (total > 1) {
            initSlides();
            timeout = setTimeout(checkPosition, slideLength);
        }
        console.log(currentSlide.data('slide').logo);
        if (currentSlide.data('slide').logo === 'light') {
            dom.attr('data-dark', '');
        } else {
            dom.attr('data-dark', null);
        }

        Dispatch.emit(Events.HERO_SLIDE, currentSlide.data('slide') );
    };

    const destroy = () => {
        Viewport.off('scroll', checkPosition);
    };

    return {
        init,
        destroy
    };
};
