import $ from '@vaersaagod/tools/Dom';

const loadMasonry = require('bundle-loader?lazy&name=[name]!masonry-layout');

export default el => {
    const dom = $(el);
    const items = dom.find('[data-item]');
    let msnry;

    const init = () => {
        loadMasonry(Masonry => {
            msnry = new Masonry(el, {
                itemSelector: '[data-item]',
                columnWidth: '[data-spacer]',
                percentPosition: true,
                gutter: 0,
                transitionDuration: 0.1
            });
        });
    };

    const destroy = () => {
        msnry.destroy();
    };

    return {
        init,
        destroy
    };
};