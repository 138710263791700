import $ from '@vaersaagod/tools/Dom';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default (el, props) => {
    const dom = $(el);
    const galleryLinks = dom.find('[data-gallery-link]');
    const imageUids = galleryLinks.map(item => $(item).data('gallery-link'));
    const images = galleryLinks.filter((item, index) => imageUids.indexOf($(item).data('gallery-link')) === index).find('img').clone();
    const modal = dom.find('[data-modal]');
    const modalSlider = modal.find('[data-modal-slider]');
    const copyButton = dom.find('[data-copy]');
    const closeButton = dom.find('[data-gallery-close]');
    let selectedUid = $('[data-selected-index]').data('selected-index') || false;
    let modalHidden = selectedUid === false;
    let tl = new TimelineMax();

    let flkty;

    const setFocusToFlickity = () => {
        if (!flkty || !modalSlider.length) {
            return;
        }
        try {
            modalSlider.get(0).focus();
        } catch (error) {
            console.error(error);
        };
    };

    const keyup = e => {
        const key = e.keyCode || e.which;

        if (key === 27 && !modalHidden) {
            hide();
        }
    };

    const hide = () => {
        modalHidden = true;
        window.history.pushState({uid: false, showModal: false}, '', props.entryPath);
        // modal.css('display', 'none');

        tl.kill();

        tl = new TimelineMax()
            .to(copyButton.find('[data-text]').get(0), 0.5, { opacity: 0.0001, x: -10 })
            .to(closeButton.find('[data-text]').get(0), 0.5, { opacity: 0.0001, x: 10 }, 0)
            .to([copyButton.get(0), closeButton.get(0)], 0.5,{ opacity: 0.0001 }, 0.4)
            .to(modalSlider.get(0), 0.5, { opacity: 0.0001 }, 0.4)
            .to(modal.get(0), 0.5, { opacity: 0.0001, clearProps: 'all' });
    };

    const show = (i = selectedUid, push = true) => {
        modalHidden = false;
        // modal.css('display', 'block');

        tl = new TimelineMax()
            .set(modal.get(0), { display: 'block', opacity: 0.0001 })
            .set(modalSlider.get(0), { opacity: 0.0001 })
            .set([copyButton.get(0), closeButton.get(0)], { opacity: 0.0001 })
            .set(copyButton.find('[data-text]').get(0), { opacity: 0.0001, x: -10 })
            .set(closeButton.find('[data-text]').get(0), { opacity: 0.0001, x: 10 })
            .to(modal.get(0), 0.5, { opacity: 1 })
            .to(modalSlider.get(0), 0.7, { opacity: 1 }, 0.5)
            .to([copyButton.get(0), closeButton.get(0)], 0.5, { opacity: 1 }, 0.6)
            .to([copyButton.find('[data-text]').get(0), closeButton.find('[data-text]').get(0)], 0.5, { opacity: 1, x: 0 }, 0.7)
            .call(() => {
                if (push) {
                    window.history.pushState({uid: imageUids[i], showModal: true}, '', `/galleri/${props.entryUid}/${imageUids[i]}`);
                }
                
                flkty.select(i, false, true);
                flkty.resize();
            }, null, null, 0.1);


        setFocusToFlickity();

        // window.history.pushState({uid: false, showModal: false}, '', props.entryPath);
        
    };

    const onClick = e => {
        e.preventDefault();
        
        show(imageUids.indexOf($(e.triggerTarget).data('gallery-link')), true);
    };

    const onSelect = i => {
        if (modalHidden) {
            return;
        }
        console.log('onSelect', i);
        selectedUid = imageUids[i];
        window.history.replaceState({uid: selectedUid, showModal: true}, '', `/galleri/${props.entryUid}/${imageUids[i]}`);
    };

    const onPopstate = e => {
        console.log('popstate', e);

        if (e.state.showModal && modalHidden) {
            show(null, false);
        } else if (!e.state.showModal && !modalHidden) {
            hide();
        }
    };

    const copy = e => {
        e.preventDefault();
        const node = e.triggerTarget;
        const copyInput = $(`<input type="text" value="${document.location.href}">`);
        $('body').append(copyInput);
        copyInput.focus();
        copyInput.get(0).select();
        document.execCommand('copy');
        copyInput.remove();

        const nodeText = $(node).find('[data-text]').html();
        $(node).find('[data-text]').html($(node).data('copy').success);

        setTimeout(() => {
            $(node).find('[data-text]').html(nodeText);
        }, 3000);
    };

    const onCloseClick = e => {
        e.preventDefault();
        if (!modalHidden) {
            hide();
        }
    };

    const init = () => {
        console.log(selectedUid);
        window.history.replaceState({uid: selectedUid, showModal: !modalHidden}, '', `${document.location.pathname}`);

        modalSlider.css('opacity', 0);
        images.addClass('u-objectfit u-objectfit--contain lazypreload');
        modalSlider.append(images);
        images.wrap('<div class="pos-a u-fill" data-modal-item></div>');
        window.addEventListener('popstate', onPopstate);

        loadFlickity(Flickity => {
            flkty = new Flickity(modalSlider.get(0), {
                cellSelector: '[data-modal-item]',
                wrapAround: false,
                contain: true,
                pageDots: false,
                prevNextButtons: true,
                arrowShape: 'M48.63 1.37l3.88 3.89-42 41.99H100v5.5H10.51l42 41.99-3.88 3.89L0 50z',
                draggable: true,
                dragThreshold: 1,
                cellAlign: 'center',
                setGallerySize: false,
                percentPosition: true,
                accessibility: true,
                initialIndex: selectedUid ? imageUids.indexOf(selectedUid) : 0
            });

            flkty.on('select', onSelect);

            modalSlider.css('opacity', 1);

            if (!modalHidden) {
                requestAnimationFrame(setFocusToFlickity);
            }

        });

        if (modal.hasClass('d-b')) {
            modal.css('display', 'block');
            modal.addClass('d-n');
        }
        
        galleryLinks.on('click', onClick);
        document.addEventListener('keyup', keyup);
        copyButton.on('click', copy);
        closeButton.on('click', onCloseClick);
        
    };

    const destroy = () => {
        document.removeEventListener('keyup', keyup);
        galleryLinks.off('click', onClick);
        window.removeEventListener('popstate', onPopstate);
        copyButton.off('click', copy);
        closeButton.off('click', onCloseClick);
        // if (modalClose.length) {
        //     modalClose.off('click', onCloseClick);
        // }
    };

    return {
        init,
        destroy
    };
};
