import './lib/polyfills';
import lazySizes from './lib/lazysizes';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import 'gsap';
import ScrollTo from './modules/ScrollTo';
import Reveal from './modules/Reveal';
import Fake from './modules/Fauxjax';
import ViewportHeight from './modules/ViewportHeight';
require('intersection-observer');
/*
*   Bootstrap app
*
 */
const init = () => {
    Viewport.init();
    Viewport.releaseTabbing();
    Components.init();
    lazySizes.init();
    ScrollTo.init();
    Reveal.init();
    Fake.init();
    ViewportHeight.init();
};

require('doc-ready')(init);
