import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

const OFFSET_BUFFER = 5;
const OFFSET_TOP = 30;
const OFFSET_TRANSPARENCY = 150;

export default el => {

    const $el = $(el);

    const content = $el.find('[data-header-content]').get(0);
    const bar = $el.find('[data-header-bar]').get(0);
    const logo = $el.find('[data-logo]').get(0);
    const inverseLogo = $el.find('[data-logo-inverse]').get();
    const inverseLogoInner = $el.find('[data-logo-inner]').get();

    const canBeTransparent = $('body').hasClass('has-transparent-bar');
    let isTransparent = canBeTransparent;

    let $darkItems;
    let darkItemsObserver;

    let logoHeight;
    let logoTop;
    let logoBottom;

    let scrollHandler;
    let lastScrollTop = -1;
    let isHidden;

    const isSmall = () => Viewport.width < 750;

    const isMenuOpen = () => $el.hasClass('js-menu-open');

    const destroyDarkItemsObserver = () => {
        if (!darkItemsObserver) {
            return;
        }
        darkItemsObserver.disconnect();
        darkItemsObserver = null;
    };

    const createDarkItemsObserver = () => {

        destroyDarkItemsObserver();

        darkItemsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                $(entry.target).data('intersecting', entry.isIntersecting);
            });
            scrollHandler();
        }, {
            root: null,
            rootMargin: '0px',
            threshold: [0]
        });

        $darkItems = $('[data-dark]');
        $darkItems.each(node => {
            darkItemsObserver.observe(node);
        });

    };

    const setLogoHeightAndOffset = () => {
        const rect = logo.getBoundingClientRect();
        logoHeight = rect.height;
        logoTop = rect.top;
        logoBottom = logoHeight + logoTop;
    };

    const toggleTransparency = () => {
        if (!canBeTransparent) {
            return;
        }
        isTransparent = !isTransparent;
        $el.toggleClass('is-opaque');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        TweenMax.to([content, bar], 0.35, { yPercent: 0, ease: 'Quad.easeOut' });
    };

    const hide = () => {
        if (isHidden || isMenuOpen() || !isSmall()) {
            return;
        }
        isHidden = true;
        TweenMax.to([content, bar], 0.25, { yPercent: -100, ease: 'Quad.easeIn' });
    };

    const onScroll = () => {

        /**
         * Smaller screens
         */
        if (isSmall()) {

            TweenMax.set([inverseLogo, inverseLogoInner], { clearProps: 'y' });

            const scrollTop = Viewport.scrollTop;

            if (Math.abs(scrollTop - lastScrollTop) <= OFFSET_BUFFER) {
                return;
            }

            const isScrollingUp = scrollTop < lastScrollTop;

            if (isScrollingUp) {
                if (!isTransparent && scrollTop < OFFSET_TRANSPARENCY) {
                    toggleTransparency();
                }
                show();
            } else if (scrollTop > OFFSET_TOP) {
                hide();
                if (isTransparent && scrollTop >= OFFSET_TRANSPARENCY) {
                    toggleTransparency();
                }
            }
            lastScrollTop = scrollTop;

            return;
        }

        /**
         * Larger screens
         */
        TweenMax.set([content, bar], { clearProps: 'yPercent' });

        const intersecting = $darkItems.get().reduce((carry, node) => {
            const $node = $(node);
            if (!$node.data('intersecting')) {
                return carry;
            }
            const rect = node.getBoundingClientRect();
            const posTop = rect.top;
            const posBottom = posTop + rect.height;
            if (!(posTop <= logoBottom && posBottom > logoTop)) {
                return carry;
            }
            return carry.concat([node]);
        }, []);

        if (intersecting.length) {

            const darkItem = intersecting[0];
            const { top, height } = darkItem.getBoundingClientRect();
            const posTop = Math.floor(top);
            const posBottom = Math.floor(top + height);

            if (posTop < logoTop && posBottom > logoBottom) {
                TweenMax.set(inverseLogo, { y: -logoHeight });
                TweenMax.set(inverseLogoInner, { y: logoHeight });
            } else if (posTop > logoTop) {
                const posY = logoBottom - posTop;
                TweenMax.set(inverseLogo, { y: -posY });
                TweenMax.set(inverseLogoInner, { y: posY });
            } else if (posBottom < logoBottom) {
                const posY = logoHeight + (logoBottom - posBottom);
                TweenMax.set(inverseLogo, { y: -posY });
                TweenMax.set(inverseLogoInner, { y: posY });
            }

        } else {
            TweenMax.set([inverseLogo, inverseLogoInner], { clearProps: 'y' });
        }

    };

    const onResize = () => {
        setLogoHeightAndOffset();
        createDarkItemsObserver();
        onScroll();
    };

    const onHeroSlide = (key, data) => {
        const { logo } = data;
        if (logo === 'light') {
            content.classList.add('c-white');
        } else {
            content.classList.remove('c-white');
        }
        createDarkItemsObserver();
        onScroll();
    };

    const onMenuToggle = () => {
        $el.toggleClass('js-menu-open');
        if (isMenuOpen()) {
            show();
        }
    };

    const onMenuToggleClick = e => {
        e.preventDefault();
        Dispatch.emit(Events.MENU_TOGGLE);
    };

    const init = () => {

        setLogoHeightAndOffset();

        createDarkItemsObserver();

        Dispatch.on(Events.HERO_SLIDE, onHeroSlide, true);
        Dispatch.on(Events.MENU_TOGGLE, onMenuToggle, true);

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onResize);
        Viewport.on('scroll', onScroll);

        $el.on('click', '[data-menu-toggle]', onMenuToggleClick);


        scrollHandler = onScroll;

        onResize();

        TweenMax.fromTo(el, 0.75, { opacity: 0.0001 }, { opacity: 1, delay: 0.15 });

    };

    const destroy = () => {

        destroyDarkItemsObserver();

        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onResize);
        Viewport.off('scroll', onScroll);

        Dispatch.off(Events.HERO_SLIDE, onHeroSlide);
        Dispatch.off(Events.MENU_TOGGLE, onMenuToggle);

        $el.off('click');

    };

    return {
        init,
        destroy
    };

};
