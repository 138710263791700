import $ from '@vaersaagod/tools/Dom';

const fakeIt = e => {
    if (e.triggerTarget.host !== document.location.host || e.triggerTarget.hash !== "" || $(e.triggerTarget).attr('data-toggle') !== null || $(e.triggerTarget).attr('data-gallery-link') !== null) {
        return;
    }

    console.log(e);

    e.preventDefault();

    const url = e.triggerTarget.href;

    new TimelineMax()
        .to($('body').get(0), 0.15,{ opacity: 0.0001 })
        .call(() => {
            document.location = url;
        });
};

const init = () => {
    $('body').on('click', 'a', fakeIt);
};

export default {
    init
};