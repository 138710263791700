import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
export default el => {
    const dom = $(el);



    const onShareClick = e => {
        e.preventDefault();

        const href = e.triggerTarget.href;
        const left = (Viewport.width / 2) - (600 / 2);
        const top = (Viewport.height / 2) - (600 / 2);
        window.open(href, 'shareWindow', `height=600,width=600, top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no`);
    };

    const init = () => {
        dom.on('click', 'a', onShareClick);
    };

    const destroy = () => {
        dom.off('click', 'a', onShareClick);
    };

    return {
        init,
        destroy
    };
};