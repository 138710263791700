import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const panel = dom.find('[data-panel]');
    const menuPoints = dom.find('[data-menu-point]');
    let timeline = new TimelineMax();

    const keyup = e => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            Dispatch.emit(Events.MENU_TOGGLE);
        }
    };

    const open = () => {
        document.addEventListener('keyup', keyup);
        timeline.kill();
        timeline = new TimelineMax()
            .set(el, { display: 'block' })
            .set(panel.get(0), { xPercent: 100 })
            .to(panel.get(0), 0.65, { xPercent: 0, ease: 'Quint.easeOut', clearProps: 'all' })
            .staggerFrom(menuPoints.nodes, 0.6, { clearProps: 'all', opacity: 0, x: 20, ease: 'Quad.easeOut' }, 0.1, 0.2 );
    };

    const close = () => {
        document.removeEventListener('keyup', keyup);
        timeline.kill();
        timeline = new TimelineMax()
            .to(panel.get(0), 0.75, { clearProps: 'all', xPercent: 100, ease: 'Quint.easeInOut' })
            .set(el, { clearProps: 'all', immediateRender: false })
            .set(menuPoints.nodes, { clearProps: 'all', immediateRender: false });
    };

    const onToggle = () => {
        const isOpen = dom.data('open');

        if (isOpen) {
            close();
        } else {
            open();
        }

        dom.data('open', !isOpen);
    };

    const init = () => {
        dom.data('open', false);
        Dispatch.on(Events.MENU_TOGGLE, onToggle);

        const active = menuPoints.find('a').filter(m => m.href === document.location.href);
        $(active).addClass('bb-1s bcolor-cc');
    };

    const destroy = () => {
        dom.data('open', false);
        Dispatch.off(Events.MENU_TOGGLE, onToggle);
    };

    return {
        init,
        destroy
    };
};