import $ from '@vaersaagod/tools/Dom';
import VideoAutoplay from '../modules/VideoAutoplay';

export default el => {
    const dom = $(el);
    let controller;
    let videoInited = false;

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const { isIntersecting } = entry;

            if (isIntersecting && !controller.isPlaying && controller.canAutoplay) {
                controller.playAndCatch();
            } else if (!isIntersecting && controller.isPlaying) {
                controller.pause();
            }
        });
    }, {
        root: null,
        threshold: 0
    });

    const onStateChange = status => {
        console.log('loop.onStateChange', status, controller.isPlaying);

        if (videoInited && status === controller.PLAY && !controller.isPlaying) {
            dom.addClass('is-playing');
        } else if (videoInited && status === controller.PAUSE) {
            dom.removeClass('is-playing');
        } else if (!videoInited && status === controller.CANPLAY) {
            videoInited = true;
            observer.observe(el);
        } else if (!videoInited && status === controller.CANTPLAY) {

        }
    };

    const init = () => {
        controller = new VideoAutoplay(el, onStateChange, false);
    };

    const destroy = () => {
        controller.destroy();
    };

    return {
        init,
        destroy
    };
};
