import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

const onClick = e => {
    e.preventDefault();

    Viewport.scrollTo($($(e.triggerTarget).data('scroll-to')).get(0), {
        duration: 500
    });
};

const init = () => {
    $('body').on('click', '[data-scroll-to]', onClick);
};

export default {
    init
};
