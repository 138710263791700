import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

const revealItems = $('[data-reveal]');
let observer;

const onObserve = entries => {
    const items = [];
    entries.forEach(entry => {
        const { isIntersecting } = entry;

        if (isIntersecting) {
            items.push(entry.target);
            observer.unobserve(entry.target);
        }
    });
    TweenMax.set(items, { 'will-change': 'opacity, transform'});
    TweenMax.staggerTo(items, 0.75, { opacity: 1, y: 0, clearProps: 'opacity, y, will-change' }, 0.2);
};

const init = () => {
    observer = new IntersectionObserver(onObserve, {
        root: null,
        rootMargin: `0px 0px -${Math.floor(Viewport.height/10)}px 0px`,
        threshold: 0
    });
    revealItems.each(el => {
        TweenMax.set(el, { opacity: 0.0001, y: $(el).data('reveal').y || 30 });
        observer.observe(el);
        $(el).attr('data-reveal', null);
    });
};

export default {
    init
};
