import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default (el, props) => {
    const dom = $(el);
    const slider = dom.find('[data-slider]');
    const navButtons = dom.find('[data-slider-nav-button]');
    const slides = dom.find('[data-slide]');
    let flkty;
    let navItems;
    let touchingCarousel = false;
    let touchStartCoords;
    let isGrouped = false;
    props = props || {};

    const onNav = e => {
        e.preventDefault();
        const node = e.triggerTarget;
        console.log(parseInt($(node).data('index'), 10));
        flkty.select(parseInt($(node).data('index'), 10));
    };

    const onImageClick = e => {
        console.log(e);

        const slide = $(e.triggerTarget).parent('[data-slide]');

        flkty.select(parseInt(slides.index(slide.get(0)), 10));
    };

    const onSlideClick = (event, pointer, cellElement, cellIndex) => {
        console.log(event, pointer, cellElement, cellIndex);
        if (isGrouped) {
            flkty.selectCell(cellIndex, props.wrapAround || false);
        } else {
            flkty.select(cellIndex, props.wrapAround || false);
        }
        
    };

    const onChange = i => {
        navItems.removeClass('is-active');
        navItems.eq(i).addClass('is-active');
    };

    const groupCellsOnLarge = () => {
        if (Viewport.width >= 980) {
            isGrouped = true;
        } else {
            isGrouped = false;
        }
        flkty.options.groupCells = Viewport.width >= 980 ? 2 : false;
        flkty.resize();
        flkty.reposition();
    };

    const prev = () => {
        if (!flkty) {
            return;
        }
        flkty.previous();
    };

    const next = () => {
        if (!flkty) {
            return;
        }
        flkty.next();
    };

    const init = () => {
        console.log(props.hasNav);
        loadFlickity(Flickity => {
            flkty = new Flickity(slider.get(0), {
                cellSelector: '[data-slide]',
                wrapAround: props.wrapAround || false,
                contain: true,
                pageDots: props.hasNav,
                prevNextButtons: false,
                draggable: true,
                dragThreshold: 10,
                cellAlign: props.cellAlign || 'center',
                setGallerySize: true,
                percentPosition: true,
                accessibility: true,
                freeScroll: props.freeScroll || false
            });

            if (props.hasNav) {
                // const sliderNav = dom.find('[data-slider-nav]');
                // sliderNav.append(dom.find('[data-slider-nav-item]'));
                navItems = dom.find('[data-slider-nav-item]');
                navItems.eq(flkty.selectedIndex).addClass('is-active');

                navItems.on('click', onNav);
                navButtons.on('click', onNav);
                flkty.on('change', onChange);

            }

            if(props.bleed && props.layout === 'portrait') {
                Viewport.on('breakpoint', groupCellsOnLarge);
                groupCellsOnLarge();
            }

            flkty.on('staticClick', onSlideClick);
            // dom.on('click', '[data-slide] img', onImageClick);


            dom
                .on('click', '[data-slider-nextprev-button="next"]', next)
                .on('click', '[data-slider-nextprev-button="prev"]', prev);
        });

        document.body.addEventListener('touchstart', function(e) {
            if (e.target.closest('.flickity-slider')) {
              touchingCarousel = true;
            } else {
              touchingCarousel = false;
              return;
            }

            touchStartCoords = {
              x: e.touches[0].pageX,
              y: e.touches[0].pageY
            }
          });

          document.body.addEventListener('touchmove', function(e) {
            if (!(touchingCarousel && e.cancelable)) {
              return;
            }

            let moveVector = {
              x: e.touches[0].pageX - touchStartCoords.x,
              y: e.touches[0].pageY - touchStartCoords.y
            };

            if (Math.abs(moveVector.x) > 7)
              e.preventDefault()

          }, {passive: false});
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
