import $ from '@vaersaagod/tools/Dom';

export default el => {
    const dom = $(el);
    const toggles = dom.find('[data-toggle]');

    const open = answer => {
        TweenMax.set(answer.get(0), { height: 'auto', display: 'block' });
        TweenMax.from(answer.get(0), 0.5, { height: 0 });
    };

    const close = answer => {
        TweenMax.to(answer.get(0), 0.5, { height: 0, clearProps: 'all' });
    };

    const onToggle = e => {
        e.preventDefault();
        const node = e.triggerTarget;
        const answer = $(node).parent('[data-question]').find('[data-answer]');

        const isOpen = $(node).data('open');

        $(node).toggleClass('is-open');

        if (isOpen) {
            close(answer);
        } else {
            open(answer);
        }

        $(node).data('open', !isOpen);
    };

    const init = () => {
        toggles.data('open', false);
        toggles.on('click', onToggle);
    };

    const destroy = () => {
        toggles.data('open', false);
        
    };

    return {
        init,
        destroy
    };
};