import Viewport from '@vaersaagod/tools/Viewport';

const resize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const init = () => {
    Viewport.on('resize', resize);
    document.addEventListener('touchstart', () => {
        Viewport.off('resize', resize);
    });
    document.addEventListener('toucend', () => {
        Viewport.on('resize', resize);
    });
};

export default {
    init
};
