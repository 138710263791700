import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default class VideoAutoplay {
    static getVideoFormat() {
        return Viewport.height / Viewport.width > 1 ? 'portrait' : 'landscape';
    }

    constructor(el, cb, fallbackToClick) {
        this.cb = cb;
        this.$el = $(el);

        this.$video = this.$el.find('[data-video]');
        this.video = this.$video.get(0);
        this.$image = this.$el.find('img');

        this.hasAudioTrack = this.checkAudio();
        this.autoplayAllowed = false;

        this.videoIsPlaying = false;
        this.videoFallbackTimer = null;

        this.fallbackToClick = fallbackToClick;

        this.videoSources = {
            landscape: this.$video.attr('data-landscape') || this.$video.attr('data-portrait'),
            portrait: this.$video.attr('data-portrait') || this.$video.attr('data-landscape')
        };

        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onLoadStart = this.onLoadStart.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onFallbackTimer = this.onFallbackTimer.bind(this);
        this.onResize = this.onResize.bind(this);
        this.init();
    }

    init() {
        console.log('init');
        if (!this.video.canPlayType || !this.video.canPlayType('video/mp4')) {
            this.cantPlayVideo();
            return;
        }

        this.video.addEventListener('play', this.onPlay);
        this.video.addEventListener('pause', this.onPause);

        this.maybeSwapSource();

        Viewport.on('resize', this.onResize);
    }

    destroy() {
        this.killVideo();
        this.video.removeEventListener('play', this.onPlay);
        this.video.removeEventListener('pause', this.onPause);
    }

    onResize() {
        console.log('onReseize');
        this.maybeSwapSource();
    }

    stopFallbackTimer() {
        console.log('stopFallbackTimer');
        clearTimeout(this.videoFallbackTimer);
        this.videoFallbackTimer = null;
    }

    startFallbackTimer(interval) {
        console.log('startFallbackTimer');
        this.stopFallbackTimer();
        this.videoFallbackTimer = setTimeout(this.onFallbackTimer, interval);
    }

    addVideoEventListeners() {
        this.video.addEventListener('timeupdate', this.onTimeUpdate);
        this.video.addEventListener('loadstart', this.onLoadStart);
        this.video.addEventListener('loadedmetadata', this.onLoadStart);
        this.video.addEventListener('loadeddata', this.onLoadStart);
        this.video.addEventListener('canplay', this.onLoadStart);
    }

    removeVideoEventListeners() {
        this.video.removeEventListener('timeupdate', this.onTimeUpdate);
        this.video.removeEventListener('loadstart', this.onLoadStart);
        this.video.removeEventListener('loadedmetadata', this.onLoadStart);
        this.video.removeEventListener('loadeddata', this.onLoadStart);
        this.video.removeEventListener('canplay', this.onLoadStart);
    }

    killVideo() {
        this.stopFallbackTimer();
        this.removeVideoEventListeners();
        this.video.pause();
        this.videoIsPlaying = false;
    }

    maybeSwapSource() {
        if (!this.videoSources.landscape || !this.videoSources.portrait) {
            return;
        }

        const currentFormat = VideoAutoplay.getVideoFormat();
        const currentSource = this.video.getAttribute('src');
        const wantedSource = this.videoSources[currentFormat];

        if (currentSource === wantedSource) {
            return;
        }

        this.killVideo();
        this.video.src = wantedSource;

        this.playAndCatch();
    }

    pause() {
        this.video.pause();
        this.videoIsPlaying = false;
    }

    playAndCatch() {
        this.addVideoEventListeners();

        this.video.muted = true;

        this.startFallbackTimer(1000);

        const promise = this.video.play();

        if (promise !== undefined) {
            promise.then(() => {
                console.log('playAndCatch.play');
                TweenMax.to(this.video, 0.5, { opacity: 1 });
                this.stopFallbackTimer();
                this.onPlayerStateChange({ data: 3 });
                this.autoplayAllowed = true;
                if (!Viewport.visible(this.video)) {
                    console.log('playAndCatch.pausing');
                    this.video.pause();
                }
            }).catch(e => {
                console.log(['playAndCatch.catch', e]);
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    console.log('playAndCatch.catch: killing it');
                    this.cantPlayVideo();
                }
            });
        }
    }

    cantPlayVideo() {
        console.log('cantPlayVideo');
        if (this.fallbackToClick) {
            this.stopFallbackTimer();
            this.removeVideoEventListeners();
            this.onPlayerStateChange({ data: 4 });
        } else {
            this.killVideo();
            this.$image.addClass('lazyload');
            if (this.$video.length) {
                this.$video.remove();
            }
        }
    }

    onPlayClick() {
        const promise = this.video.play();

        if (promise !== undefined) {
            promise.then(() => {
                console.log('onPlayClick.play');
                this.onPlayerStateChange({ data: 1 });
                this.videoIsPlaying = true;
            }).catch(e => {
                console.log(['onPlayClick.catch', e]);
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    console.log('onPlayClick.catch: killing it');
                    this.fallbackToClick = false;
                    this.cantPlayVideo();
                }
            });
        }
    }

    onPlay() {
        console.log('onPlay');
        this.onPlayerStateChange({ data: 1 });
    }

    onPause() {
        console.log('onPause');
        this.onPlayerStateChange({ data: 2 });
    }

    onFallbackTimer() {
        console.log('onFallbackTimer');
        this.cantPlayVideo();
    }

    onTimeUpdate() {
        if (!this.video || this.video.currentTime < 0.001) {
            return;
        }
        this.removeVideoEventListeners();
        this.stopFallbackTimer();
        this.$video.addClass('is-playing');
        this.videoIsPlaying = true;
    }

    onLoadStart() {
        if (this.videoIsPlaying) {
            return;
        }
        console.info(':::: LOAD START!!!!!!!! ::::');
        this.startFallbackTimer(6000);
    }

    onPlayerStateChange(e) {
        console.log('onPlayerStateChange');
        this.cb(e.data);
    }

    checkAudio() {
        return this.video.mozHasAudio || Boolean(this.video.webkitAudioDecodedByteCount) || Boolean(this.video.audioTracks && this.video.audioTracks.length);
    }

    toggleSound() {
        if (this.video.muted) {
            this.video.muted = false;
        } else {
            this.video.muted = true;
        }
    }

    get PLAY() {
        return 1;
    }

    get PAUSE() {
        return 2;
    }

    get CANPLAY() {
        return 3;
    }

    get CANTPLAY() {
        return 4;
    }

    get canAutoplay() {
        return this.autoplayAllowed;
    }

    get isPlaying() {
        return this.videoIsPlaying;
    }

    get hasAudio() {
        return this.checkAudio();
    }
}
