import $ from '@vaersaagod/tools/Dom';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default (el, props) => {
    const dom = $(el);
    const $modalIframe = dom.find('[data-modal-iframe]');
    const copyButton = dom.find('[data-copy]');
    const closeButton = dom.find('[data-gallery-close]');
    
    let triggerProps = {};
    let currentPath = document.location.pathname;
    let modalHidden = true;
    let tl = new TimelineMax();
    

    const keyup = e => {
        const key = e.keyCode || e.which;

        if (key === 27 && !modalHidden) {
            hide();
        }
    };

    const hide = () => {
        modalHidden = true;
        //window.history.replaceState({ }, '', triggerProps.entryPath);
        // modal.css('display', 'none');

        window.history.replaceState({ }, '', `${currentPath}`);

        tl.kill();

        tl = new TimelineMax()
            .to(copyButton.find('[data-text]').get(0), 0.5, { opacity: 0.0001, x: -10 })
            .to(closeButton.find('[data-text]').get(0), 0.5, { opacity: 0.0001, x: 10 }, 0)
            .to([copyButton.get(0), closeButton.get(0)], 0.5,{ opacity: 0.0001 }, 0.4)
            .to($modalIframe.get(0), 0.5, { opacity: 0.0001 }, 0.4)
            .to(dom.get(0), 0.5, { opacity: 0.0001, clearProps: 'all' });
    };

    const show = () => {
        modalHidden = false;
        // modal.css('display', 'block');
        
        window.history.replaceState({ }, '', `${currentPath}?threed=${triggerProps.blockId}`);

        tl = new TimelineMax()
            .set(dom.get(0), { display: 'block', opacity: 0.0001 })
            .set($modalIframe.get(0), { opacity: 0.0001 })
            .set([copyButton.get(0), closeButton.get(0)], { opacity: 0.0001 })
            .set(copyButton.find('[data-text]').get(0), { opacity: 0.0001, x: -10 })
            .set(closeButton.find('[data-text]').get(0), { opacity: 0.0001, x: 10 })
            .to(dom.get(0), 0.5, { opacity: 1 })
            .to($modalIframe.get(0), 0.7, { opacity: 1 }, 0.5)
            .to([copyButton.get(0), closeButton.get(0)], 0.5, { opacity: 1 }, 0.6)
            .to([copyButton.find('[data-text]').get(0), closeButton.find('[data-text]').get(0)], 0.5, { opacity: 1, x: 0 }, 0.7)
            .call(() => {
                //
            }, null, null, 0.1);
    };
    
    const onPopstate = e => {
        console.log('popstate', e);

        if (e.state.showModal && modalHidden) {
            show(null, false);
        } else if (!e.state.showModal && !modalHidden) {
            hide();
        }
    };

    const copy = e => {
        e.preventDefault();
        const node = e.triggerTarget;
        const copyInput = $(`<input type="text" value="${document.location.href}">`);
        $('body').append(copyInput);
        copyInput.focus();
        copyInput.get(0).select();
        document.execCommand('copy');
        copyInput.remove();

        const nodeText = $(node).find('[data-text]').html();
        $(node).find('[data-text]').html($(node).data('copy').success);

        setTimeout(() => {
            $(node).find('[data-text]').html(nodeText);
        }, 3000);
    };

    const onCloseClick = e => {
        e.preventDefault();
        if (!modalHidden) {
            hide();
        }
    };
    
    const onTriggerClick = (e) => {
        e.preventDefault();
        const $trigger = $(e.triggerTarget);
        
        triggerProps = $trigger.data('threed-modal-trigger');
        $modalIframe.attr('src', triggerProps.matterportUrl);
        show();
    };

    const init = () => {
        //console.log(selectedUid);
        //window.history.replaceState({uid: selectedUid, showModal: !modalHidden}, '', `${document.location.pathname}`);
        
        //modalSlider.css('opacity', 0);

        window.addEventListener('popstate', onPopstate);
        document.addEventListener('keyup', keyup);
        copyButton.on('click', copy);
        closeButton.on('click', onCloseClick);
        
        $('body').find('[data-threed-modal-trigger]').on('click', onTriggerClick)
        
        if (props.matterportUrl !== undefined) {
            triggerProps = props;
            $modalIframe.attr('src', triggerProps.matterportUrl);
            show();
        }
    };

    const destroy = () => {
        window.removeEventListener('popstate', onPopstate);
        document.removeEventListener('keyup', keyup);
        copyButton.off('click', copy);
        closeButton.off('click', onCloseClick);
        // if (modalClose.length) {
        //     modalClose.off('click', onCloseClick);
        // }
    };

    return {
        init,
        destroy
    };
};
